html,
body {
  height: 100%;
}

.logo {
  margin: 7px 0px;
  float: left;
  height: 50px;
}

.header-logo {
  background: #fff !important;
  min-height: 150px;
  /* max-height: 200px; */
}

.ant-layout.layout {
  min-height: 100vh;
}

.site-layout {
  padding: 0 50px;
  margin-top: 64px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.site-layout .ant-layout-content .site-layout-background {
  background: #fff;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
}

.ant-layout-content.site-layout {
  padding: 0 50px;
  margin-top: 0px;
}

@media only screen and (max-width: 768px) {
  .site-layout {
    padding: 0 25px;
  }
}

div.caseHeader,
.caseHeader div {
  padding: 0;
  margin: 0;
  height: normal;
  white-space: normal;
  display: inline-block;
  line-height: normal;
}

.centerVertically {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ecfmg-small-descriptions .ant-descriptions-item-label {
  font-size: 10px;
  line-height: normal;
  padding-bottom: 3px;
}

.ecfmg-small-descriptions .ant-descriptions-item-content {
  line-height: normal;
  overflow-wrap: anywhere;
}

.ecfmg-small-descriptions .ant-descriptions-row > th {
  padding-bottom: 0px;
}

.ecfmg-medium-table .ant-table.ant-table-small {
  font-size: 12px;
}
.ecfmg-medium-table .ant-table.ant-table-small .ant-table-tbody > tr > td,
.ecfmg-medium-table .ant-table.ant-table-small .ant-table-tbody > tr > th {
  padding: 4px 4px;
}

.ecfmg-small-table .ant-table.ant-table-small {
  font-size: 10px;
}
.ecfmg-small-table .ant-table.ant-table-small .ant-table-tbody > tr > td,
.ecfmg-small-table .ant-table.ant-table-small .ant-table-tbody > tr > th {
  padding: 2px 2px;
}

.ecfmg-small-table-pathway6 .ant-table.ant-table-small {
  font-size: 11px;
}
.ecfmg-small-table-pathway6 .ant-table.ant-table-small .ant-table-tbody > tr > td > button {
  font-size: 11px;
  height: fit-content;
}

.ecfmg-small-table-pathway6 .ant-table.ant-table-small .ant-table-tbody > tr > td,
.ecfmg-small-table-pathway6 .ant-table.ant-table-small .ant-table-tbody > tr > th {
  padding: 2px 2px;
}

.ant-table.ant-table-small > .ant-table-container > .ant-table-content > table > thead > tr > th,
.ant-table.ant-table-small > .ant-table-container > .ant-table-header > table > thead > tr > th {
  background-color: #f8efe3;
}

/* for finance settings page */
.rule-list-row {
  cursor: pointer;
}

.space-align-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.space-align-block {
  margin: 8px 4px;
  border: 1px solid #40a9ff;
  padding: 4px;
  flex: none;
}

.clickRowStyl {
  background-color: #deeff5;
}

/* for pdf viewer  */

.customCanvas {
  min-width: 80vw;

  max-width: 80vw;

  overflow: auto;
}

.customPrevBtn {
  cursor: pointer;

  display: inline-block;

  margin: 0;

  width: auto;

  color: #fa5b35;

  background-color: #f3f3f3;

  border-color: #fa5b35;

  border-width: 1px;

  border-style: solid;

  border-radius: 6px;
}

.customNextBtn {
  cursor: pointer;

  display: inline-block;

  margin: 0;

  width: auto;

  color: #fa5b35;

  background-color: #f3f3f3;

  border-color: #333333;

  border-width: 1px;

  border-style: solid;

  border-radius: 6px;
}

.customResetBtn {
  cursor: pointer;

  display: inline-block;

  margin: 0;

  width: auto;

  color: #45fa35;

  background-color: #f3f3f3;

  border-color: #45fa35;

  border-width: 1px;

  border-style: solid;

  border-radius: 6px;
}

.customPages {
  font-size: medium;

  margin: 0.5rem;
}

.customWrapper {
  height: 40px;

  font-size: 24px;

  padding: 12px;

  padding-bottom: 0;

  background-color: #fff;
}

.disabled-row {
  background-color: #dcdcdc;
  pointer-events: none;
}

.caseListTitle:hover {
  cursor: pointer;
}

/* for mini-cex and attestation pdf tabs */
.card-container p {
  margin: 0;
}
.card-container > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -16px;
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px;
  background: #fff;
}
.card-container > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #fff;
  border-color: #fff;
}
#components-tabs-demo-card-top .code-box-demo {
  padding: 24px;
  overflow: hidden;
  background: #f5f5f5;
}
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -8px;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
[data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
  background: #000;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  background: #141414;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #141414;
  border-color: #141414;
}

li.sider-icon:not(.ant-menu-item-selected) > .ant-menu-item-icon > svg,
li.sider-icon > .ant-menu-title-content > a {
  color: #000;
}

li.sider-icon.ant-menu-item-active > .ant-menu-item-icon > svg,
li.sider-icon.ant-menu-item-active > .ant-menu-title-content > a {
  color: #3f4373;
}

li.sider-icon > span.ant-menu-title-content::after {
  left: 215px;
  top: 0px;
  bottom: 0px;
  width: 2px;
  height: 100%;
  transform: scaleY(0);
  transition: transform 0.3s ease;
  background-color: #3f4373;
}

li.sider-icon > span.ant-menu-title-content:hover::after {
  transform: scaleY(0.9);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > span.ant-menu-title-content::after {
  left: 215px;
  top: 0px;
  bottom: 0px;
  width: 2px;
  height: 100%;
  transform: scaleY(0.9);
  background-color: #3f4373;
}

li.sider-icon.ant-menu-item-disabled > span.ant-menu-title-content::after {
  background-color: #fff;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(136, 144, 235, 0.5);
  color: #3f4373;
}

.ant-menu-item-selected.sider-icon > .ant-menu-title-content > a {
  color: #3f4373;
}

li.sider-icon > span.ant-menu-title-content {
  padding-left: 0px;
}

.ant-menu-inline.ant-menu-root .ant-menu-item.sider-icon > .ant-menu-title-content {
  text-overflow: unset;
}

.ant-layout-sider-trigger {
  background: #fff;
  color: #000;
}

.ant-menu-item-active.sider-icon:hover {
  color: #3f4373;
}

.header span.ant-menu-title-content::after {
  visibility: hidden;
}

.searchbar span.ant-menu-title-content::after {
  visibility: hidden;
}
